import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MAT_DATE_LOCALE} from '@angular/material/core';
import { DateheaderComponent } from '../dateheader/dateheader.component';
import { Router } from '@angular/router';
// export class DatepickerCustomHeaderExample {
//   Dateheader = DateheaderComponent;
//     date = new FormControl(moment());

// }

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD'//'DD/MMM',
  },
  display: {
    dateInput: 'MM-DD',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}]
})
export class EditContactComponent implements OnInit {
  Dateheader = DateheaderComponent;
  date = new FormControl(moment());
  editContactForm!: FormGroup
  abc: any;
  dateOfBirth: any;
  saveContactBaseLink: any='https://staging.b-intouch.me/api/export_contact/?contact_id='
  user_id: any;
  constructor(public dialog: MatDialog, private fb: FormBuilder, private message: MessagehandlingService, private api: ApiService, public dialogRef: MatDialogRef<EditContactComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private router: Router) {
    this.editContactForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      dateOfBirth: new FormControl(moment()),
      company: new FormControl(''),
      position: new FormControl(''),
      phoneNumber: new FormControl(''),
      workNumber: new FormControl(''),
      email: new FormControl(''),
      websiteUrl: new FormControl(''),
      address: new FormControl(''),
      postalCode: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      note: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.getUserData()
  }


  
 

  getUserData() {
    let formdata = new FormData();
    formdata.append('target_user_id', sessionStorage.getItem('SelectedMembersId') || '')
    this.api.aboutLandingApi(formdata).subscribe((res: any) => {
      this.user_id=res.user.contact_id
      setTimeout(() => {
      this.abc =   moment(res.user.dob).format('MM-DD')
   this.date =   new FormControl(moment(res.user.dob));
      this.editContactForm.patchValue({
        firstName: res.user.first_name,
        lastName: res.user.last_name,
        dateOfBirth: moment(res.user.dob),
        company: res.user.company,
        position: res.user.position,
        phoneNumber: res.user.phone,
        workNumber: res.user.work_phone,
        email: res.user.email,
        websiteUrl: res.user.website,
        address: res.user.address,
        postalCode: res.user.postcode,
        city: res.user.city,
        country: res.user.country,
        note: res.user.note
      })
     }, 100);
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  previewLink() {
    // const encodedName = btoa(id);
    let link = `${this.saveContactBaseLink}${btoa(this.user_id)}`
     window.open(link,'_blank')
  }

  saveContactDetail() {
    let fd = new FormData
    fd.append('id', sessionStorage.getItem('SelectedContactId') || '')
    fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
    fd.append('first_name', this.editContactForm.value.firstName)
    fd.append('last_name', this.editContactForm.value.lastName)
    fd.append('dob', this.editContactForm.value.dateOfBirth)
    fd.append('company', this.editContactForm.value.company)
    fd.append('position', this.editContactForm.value.position)
    fd.append('phone', this.editContactForm.value.phoneNumber)
    fd.append('work_phone', this.editContactForm.value.workNumber)
    fd.append('email', this.editContactForm.value.email)
    fd.append('website', this.editContactForm.value.websiteUrl)
    fd.append('address', this.editContactForm.value.address)
    fd.append('postcode', this.editContactForm.value.postalCode)
    fd.append('city', this.editContactForm.value.city)
    fd.append('country', this.editContactForm.value.country)
    fd.append('note', this.editContactForm.value.note)
    this.api.saveUserContact(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.msg, 3000)
      this.dialog.closeAll()
      const currentRoute = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/profile']); 
      });
    })
  }
}
