<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <button (click)="backToAddContent()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">{{'Back'| translate}}</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <!-- <h5 *ngIf="currentRoute=='/profile'|| currentRoute=='/profile/content' " class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add
        {{selectedSocialInfo?.name}} link to
        1 profiles</h5> -->

    <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">{{'Add'| translate}}
        {{selectedSocialInfo?.name}} {{'link to'| translate}}
        {{selectedMemberCount}}<span *ngIf="selectedMemberCount== 1" >{{'profile'| translate}}</span><span *ngIf="selectedMemberCount> 1" >{{'profiles'| translate}}</span></h5>
    <!-- <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add File</h5> -->
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap"><img src="assets/images/file_icon.png"></div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">{{selectedSocialInfo?.name}}</p>

            <!-- <p class="fs11 text_gray mt-2 mb-0 lh-1">File</p> -->
        </div>
        <div class="toggle-sec d-flex align-items-center justify-content-end pt-3 pb-0 mb-0 padding-content rightText">
            <!-- <div class="customSlideToggle rightText"> -->
                <!-- [checked]="selectedIconData.highlight==1?true:null" -->
                <!-- <mat-slide-toggle class="fs11 fontMed" formControlName="highlight"
                    *ngIf="selectedIconData.slug!='embedvideo' && selectedIconData.slug!='calendar' && selectedIconData.slug!='cv' && selectedIconData.slug!='link'&& selectedIconData.slug!='file'"
                    [checked]="selectedIconData.highlight==1? true:false" (change)="highlightbtn($event)">
                    Highlight</mat-slide-toggle> -->
            <!-- </div> -->
            <p class="text_primary text-decoration-underline fontMed fs11 mb-0 cursor_pointer" (click)="previewLink()">
                {{'Preview Link'| translate}}</p>
        </div>
        <p class="m-0 fs12 fontMed text_secondary mt-2 py-3 text-center">{{'File link will appear on your profile as a button'| translate}}</p>
        <div class="padding-content pt-0">
            <!-- form content -->
            <form class="" [formGroup]="linkForm">
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0 text-truncate">{{fileNme}}</label>
                    <p class="text_primary fontMed fs11 mb-0 me-2 cursor_pointer" (click)="fileType()">{{'Choose'| translate}}...</p>
                </div>
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">{{'Title'| translate}}</label>
                    <input
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="File" formControlName="title">
                </div>

                <div *ngIf="highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">{{'Description'| translate}}</label>
                    <textarea class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        id="description">Check out my trip to Greece’s new post!</textarea>
                </div>

                <button class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none" (click)="submitForm()">{{'Save link'| translate}}</button>
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <!-- <h2 class="fs17 fontMed mb-3 text-center">URL of your Website</h2> -->
                <p class="fs13 mb-0 pb-1 text_secondary text-center">{{'Upload the file you would like to share.'| translate}}</p>
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto"
                    data-bs-dismiss="modal" style="color: #498BE5;">{{'Got it'| translate}}!</button>
            </div>
        </div>
    </div>
</div>