<!-- 
<image-cropper *ngIf="type != 'cover'&& type!='zoom'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    (imageCropped)="imageCropped($event)" 
   ></image-cropper>
<image-cropper *ngIf="type ==='cover'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    (imageCropped)="imageCropped($event)" 
    [aspectRatio]="13/4.6"
    [maintainAspectRatio]="true"
    [cropperStaticHeight]="180"
    [cropperMaxWidth]="400"
    ></image-cropper>
    <image-cropper *ngIf="type === 'zoom'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    (imageCropped)="imageCropped($event)" 
    [aspectRatio]="13/8.8"
    [maintainAspectRatio]="true"
    [cropperStaticHeight]="200"
    [cropperMaxWidth]="295"
    ></image-cropper>

<div class="btns-sec d-flex align-items-center justify-content-center pt-4 px-1">
    <button class="btn whiteBg cancelBtn border rounded-pill px-4 py-0 fs12 fontMed px-4 h36 me-2" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h36 ms-1" (click)="cropImage()">Crop</button>
</div> -->


<image-cropper *ngIf="type != 'cover'&& type!='zoom'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [onlyScaleDown]="true"    
    [containWithinAspectRatio]="false"
    (imageCropped)="imageCropped($event)" 
   ></image-cropper>
<!-- <image-cropper *ngIf="type ==='cover'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    (imageCropped)="imageCropped($event)" 
    [aspectRatio]="13/4.6"
    [maintainAspectRatio]="true"
    [cropperStaticHeight]="180"
    [cropperMaxWidth]="400"
    ></image-cropper> -->
    <image-cropper *ngIf="type ==='cover'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="false"
    [onlyScaleDown]="true"
    (imageCropped)="imageCropped($event)" 
    [aspectRatio]="13/4.68"
    ></image-cropper>
    <!-- <image-cropper *ngIf="type === 'zoom'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    (imageCropped)="imageCropped($event)" 
    [aspectRatio]="13/8.8"
    [maintainAspectRatio]="true"
    [cropperStaticHeight]="200"
    [cropperMaxWidth]="295"
    ></image-cropper> -->
    <image-cropper *ngIf="type === 'zoom'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="false"
    [onlyScaleDown]="true"    
    (imageCropped)="imageCropped($event)" 
    [aspectRatio]="13/8.8"
    ></image-cropper>

<div class="btns-sec d-flex align-items-center justify-content-center pt-4 px-1">
    <button class="btn whiteBg cancelBtn border rounded-pill px-4 py-0 fs12 fontMed px-4 h36 me-2" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h36 ms-1" (click)="cropImage()">Crop</button>
</div>

