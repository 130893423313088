import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { environment } from 'src/environments/environment';
import { AddContentComponent } from '../add-content/add-content.component';
declare const $: any;

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss']
})
export class EditLinkComponent implements OnInit {
  highlyttoggle: boolean = false;
  // base_url = "https://staging.b-intouch.me/public/uploads/service_icons_v2/"
  searchText: any
  profileData: any = [
   ]
  selectedProfileCount: number = 0;
  selectAllActive = false;
  selectedIconData: any;
  imageBaseUrl: any;
  editLinkForm: FormGroup;
  leadCaptureIcons: any = [];
  isChecked: boolean = false
  userInfo: any = {}
  userData: any
  profileImageUrl= environment.userImage
  selectedProfileData: any = []
  selectAllCheckbox: boolean = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<EditLinkComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService, private router: Router, private message: MessagehandlingService) {
    this.editLinkForm = this.fb.group({
      highlight: data.block.highlight == '' || data.block.highlight == '0' ? false : true,
      content: data.block.content,
      title: data.block.title,
      description: data.block.description,
      hints: data.block.hints,
      custom_icon: '',
      active: '',
      country_code: '',
      dial_code: ''

    })


   }
  ngOnInit(): void {
    this.highlyttoggle=this.data.block.highlight == '' || this.data.block.highlight == '0' ? false : true,
    this.getMemberData()
    this.selectedIconData = this.data.block
    this.imageBaseUrl = environment.socialRectIcons
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }


  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.profileData = response.items
  //     }
  //   })
  // }

  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element:any) => {
        if(element.member_status!=4){
            this.profileData.push(element)
        }
      });
    })
  }
  closeDialog() {
    this.dialogRef.close();
  }


  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      let fd = new FormData
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.editLinkForm.value.highlight == '' || this.editLinkForm.value.highlight == false ? '0' : '1')
      fd.append('content', this.editLinkForm.value.content)
      fd.append('title', this.editLinkForm.value.title)
      fd.append('description', this.editLinkForm.value.highlight == true ? this.editLinkForm.value.description : '')
      fd.append('slug', this.selectedIconData?.slug)
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');

      })
    }
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  back(): void {
    this.dialogRef.close();
  }

  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;

      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }



  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)
        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }


  get content() {
    return this.editLinkForm.get('content')!.value
  }

  previewLink() {
    if (this.data.block?.base_url) {
      window.open(this.data.block?.base_url + this.content)
    } else {
      let link: any = this.content.slice(0, 5);
      if (link == "https" || link == "http:") {
        window.open(this.content)
      } else {
        window.open("https://" + this.content)
      }

    }
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedIconData?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })
  }



  submitForm() {
    if (this.editLinkForm.value.highlight && !this.editLinkForm.value.description) {
      this.message.errorMessage('Please provide a description.', 2000);
      return;
    }
    if (
      !this.editLinkForm.get('content')?.value ||
      !this.editLinkForm.get('title')?.value 
    ) {
      this.message.errorMessage('Please fill in all required fields.', 2000);
      return;
    } else {
      let formData = new FormData();
      formData.append('id', this.selectedIconData.id)
      formData.append('highlight', this.editLinkForm.value.highlight == '' || this.editLinkForm.value.highlight == false ? '0' : '1')
      formData.append('content', this.editLinkForm.value.content)
      formData.append('title', this.editLinkForm.value.title)
      formData.append('description', this.editLinkForm.value.highlight == true ? this.editLinkForm.value.description : '')
      formData.append('custom_icon', this.editLinkForm.value.custom_icon)
      formData.append('country_code', this.editLinkForm.value.country_code)
      formData.append('dial_code', this.editLinkForm.value.dial_code)
      formData.append('slug', this.selectedIconData?.slug)
      formData.append('active', '')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.api.updateLink(formData).subscribe((res: any) => {
        this.message.sucessMessage(`${this.selectedIconData?.slug} successfully updated to card`, 2000)
        this.dialog.closeAll();
      })
      const currentRoute = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
      formData.forEach((val, key) => {
      })
    }
  }


}
