import { JSONParser } from '@amcharts/amcharts5';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { environment } from 'src/environments/environment';
export interface mailtoAsset {
  title: string;
  lines: string[];
}
@Component({
  selector: 'app-contact-redirect',
  templateUrl: './contact-redirect.component.html',
  styleUrls: ['./contact-redirect.component.scss']
})
export class ContactRedirectComponent {
  color:any='#0071bc'
  userInfo: any
  showImage: any;
  userData: any;
  emailData: any
  profileData: any = [
    // { name: '', address: 'Puteaux, France', designation: 'Co-Founder', projectName: 'B in Touch', title: 'My Digital Business Card 0616930225' },
  ]
  mailtoHeader = 'mailto:?';
  subjectProp = 'subject=';
  bodyProp = 'body=';
  amp = '&amp;';
  breakStr = '%0D%0A';
  footer = 'Powered by B-teams!';
  array: any = []
  test =
    'mailto:?subject=copy signature!!!! target="_self" class="action small semi-bold enabled';

  demoSubject = 'Copy signature and paste it here';

  demoAssets: mailtoAsset[] = [
    {
      title: 'Hey',
      lines: [
        'hope all well!!',
      ],
    },
  ];
  username: any;
  profileImage: any;
  digitext= 'My Digital Business Card';
  digilink:any
  base_url: any='https://bintouch.harishparas.com/profile'
  imageUrl: any;
  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer) {
    this.activeroute.params.subscribe((params: any) => {
      this.username = params['username'];
      if (this.username) {
        this.getDetail(this.username)
      } else {
        this.router.navigate(['/']);
      }
    })
  }


  ngOnInit(): void {
    

  }


  email() {
      this.imageUrl = environment.userImage
      // let a = JSON.stringify(res.user)
      // this.userData = JSON.parse(a)
      if(this.userData.profile_photo == 1 || this.userData.profile_photo == null){
      if (this.userData.photo) {
        this.showImage = this.imageUrl +'/'+this.userData.photo
        return
      } else if (this.userData.logo != '') {
        this.showImage = this.imageUrl +'/' +this.userData.logo
        return

      } else {
        this.showImage = 'assets/images/memberProfile_icon.svg'//this.userData.logo

      }
    }else if(this.userData.profile_photo == 0){
      if (this.userData.logo != '') {
        this.showImage = this.imageUrl +'/' +this.userData.logo
        return

      } else {
        this.showImage = 'assets/images/memberProfile_icon.svg'//this.userData.logo

      }
    }

    // })

    setTimeout(() => {
      this.profileImage = `${environment.userImage}/${this.userData?.photo}`;
      this.digilink = `${this.api.viewProfileUrl}/${this.userData?.username}`
    }, 3000);
    if (this.userData?.first_name == '') {
      this.profileData.push(this.userData?.username)

    }
    else {
      this.profileData.push(this.userData?.first_name)
    }
    this.profileData.push(this.userData?.position)
    this.profileData.push(this.userData?.address)
    this.profileData.push(this.userData?.company)
    this.profileData.push(this.userData?.phone)
    this.array.push(this.profileData)

    var c: any = this.array.toString()
    this.emailData = c.split(',').join('\n')
  }


  getDetail(username:any) {
    const data = { 'username': username, type: 'primary' };

    this.api.getServices(data).subscribe((res: any) => {
      let a = JSON.stringify(res.user)
      this.userData = JSON.parse(a)
      this.email()
    })
  }

  
  getStringifiedAssets(
    assets: mailtoAsset[],
    subject: string = 'some subject you know'
  ) {
    let str = '';
    assets.forEach((asset) => {
      str =
        str +
        asset.title.toUpperCase() +
        this.breakStr +
        this.joinLines(asset.lines) +
        this.breakStr +
        this.breakStr;
    });
    const url = `${this.mailtoHeader}${this.subjectProp}${subject}&${this.bodyProp}${str}${this.footer}`;
     return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  joinLines(lines: string[]) {
    return lines.join(this.breakStr);
  }

  // copySign() {
  //   const newLine = '\n';
  //  let emailBodyText = '';
  //  emailBodyText = emailBodyText + `<span style='color: ${this.color}'><img src="${this.showImage}" style="height:50px;width:50px;border-radius:50%;"> ${this.userData.first_name + this.userData.last_name}</span> <br>`;
  //  emailBodyText = emailBodyText + `<span >${this.userData.position}</span><br>` 
  //  emailBodyText =  emailBodyText + `<span>${this.userData.company}</span><br>`;
  //  emailBodyText =  emailBodyText + `<span>${this.userData.phone}</span><br>`;
  //  emailBodyText =  emailBodyText + `<span>${this.userData.address}</span><br>`;
  //  emailBodyText =  emailBodyText + `<span style='color: ${this.color}'><a href="${this.digilink}">${this.digitext}</a></span><br>`;
  //  const el = document.createElement('div');
  //  el.innerHTML = emailBodyText;
  //  el.style.position = 'absolute';
  //  el.style.left = '-9999px';
  //  document.body.appendChild(el);
  //  const sel:any = getSelection();
  //  sel.setBaseAndExtent(el, 0, el, el.childNodes.length);
  //  document.execCommand('copy');
  //  document.body.removeChild(el);
  //  alert("Signature copied and can now be pasted into new mail!")
  // }


  copySign() {
    const newLine = '\n';
   let emailBodyText = '';
  //  emailBodyText = emailBodyText + `<span style='color: ${this.color}'><img src="${this.showImage}" style="height:50px;width:50px;border-radius:50%;"> ${this.userData.first_name}</span> <br>`;
  //  emailBodyText = emailBodyText + `<span >${this.userData.position}</span><br> `
  //  emailBodyText =  emailBodyText + `<span>${this.userData.company}</span><br>`;
  //  emailBodyText =  emailBodyText + `<span>${this.userData.phone}</span><br>`;
  //  emailBodyText =  emailBodyText + `<span>${this.userData.address}</span><br>`;
  //  emailBodyText =  emailBodyText + `<span style='color: ${this.color}'><a href="${this.digilink}">${this.digitext}</a></span><br>`;

  emailBodyText = emailBodyText + `<div style="float:left; background-color: rgb(248 247 247 / 0%);"> <img src="${this.showImage}" style="height:100px;width:100px;box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); border-radius:50%;"> </div>`;
  emailBodyText = emailBodyText + `
  <div style="margin-left: 116px;     background-color: rgb(248 247 247 / 0%);">
  <span style='color: ${this.color}; font-size: 21px;    margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2;'>${this.userData.first_name + this.userData?.last_name }</span><br>
  <span style="font-size: .875em; color:style="color:#8e8e8;">${this.userData.position}</span><br>
  <span style="color:#8e8e8;">${this.userData.company}</span><br>
  <span style="color:#8e8e8;">${this.userData.phone}</span><br>
  <span>${this.userData.address}</span><br>
  <span style='color: ${this.color}'><a style="color:#0d6efd;" href="${this.digilink}">${this.digitext}</a></span>
  </div>
   `
  // emailBodyText = emailBodyText + `<span >${this.userData.position}</span><br> `
  // emailBodyText =  emailBodyText + `<span>${this.userData.company}</span><br>`;
  // emailBodyText =  emailBodyText + `<span>${this.userData.phone}</span><br>`;
  // emailBodyText =  emailBodyText + `<span>${this.userData.address}</span><br>`;
  // emailBodyText =  emailBodyText + `<span style='color: ${this.color}'><a href="${this.digilink}">${this.digitext}</a></span><br>`;


   const el = document.createElement('div');
   el.innerHTML = emailBodyText;
   el.style.position = 'absolute';
   el.style.left = '-9999px';
   document.body.appendChild(el);
   const sel:any = getSelection();
   sel.setBaseAndExtent(el, 0, el, el.childNodes.length);
   document.execCommand('copy');
   document.body.removeChild(el);
   alert("Signature copied and can now be pasted into new mail!")

  //  alert('Text is copied, and can now be pasted into outlook new mail');
  }
}

