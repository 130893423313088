import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ReferralComponent } from '../modals/referral/referral.component';
import { SendInvitesComponent } from '../modals/send-invites/send-invites.component';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { EventService } from 'src/app/common/event/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // added on 29 nov 2022 start
  userInfo: any;
  usersubscription:any
  imageUrl = environment.userImage
  count: any;
  total: any
  MemberData: any = []
  memberLoggedIn: any;
  percentagevalue: any;
  constructor(public dialog: MatDialog, public router: Router, private api: ApiService,public message:MessagehandlingService, private eventService:EventService,private translate:TranslateService) {
  }

  ngOnInit(): void {
     this.userInfo = JSON.parse(sessionStorage.getItem('userinfo') || '')
    this.usersubscription=JSON.parse(sessionStorage.getItem('usersubscription') || '')
    this.getMemberState()
    this.getuser();
   
    this.eventService.getProfileObservable().subscribe((res:any)=>{
      if(res!=undefined){
        this.userInfo = res

      }
      
    })

    
  }


  showErrorMessage(usersubscription: any) {
    const subscriptionMessage = this.translate.instant('You have to take subscription for this feature.');
    if (usersubscription === null) {
       this.message.errorMessage(subscriptionMessage, 2000);
    }
  }


  ngAfterViewInit() {

  }

  referral(): void {
    this.dialog.open(ReferralComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '875px',
    });
  }


  sendInvite(): void {
    this.dialog.open(SendInvitesComponent, {
      panelClass: ['cmn_modal', 'modal_md'],
      width: '570px',
    });
  }

  getMemberState() {
    let fd = new FormData
    this.api.getMemberState(fd).subscribe((res: any) => {
      this.count = res.count
      this.total = res.total
      this.memberLoggedIn = this.count / this.total
      setTimeout(() => {
        this.percentagevalue = (this.count * 100) / this.total;
      }, 1000);

    })
  }

  profile() {
    sessionStorage.setItem('SelectedMembersId', this.userInfo.user_id)
    sessionStorage.setItem('username', this.userInfo.username)
    sessionStorage.setItem('SelectedContactId', this.userInfo.contact_id)
    sessionStorage.setItem('SelectedProfileType', this.userInfo.selected_profile)    //check
    sessionStorage.setItem('lockStatus', this.userInfo.lock_status)
    this.router.navigate(['/profile'])
  }

  getuser() {
    let dt: any = {
      'target_user_id': this.userInfo.user_id
    }
    this.api.aboutLandingApi(dt).subscribe((res: any) => {
      this.userInfo = res.user
    })
  }



}
