<div class="dialog_head p-5 pb-4 position-relative">
    <button class="btn p-1 border-0 modal-close d-flex align-items-center justify-content-center" mat-dialog-close><img
            src="assets/images/modal-cross.svg"></button>
    <h2 class="fs17 mb-0 text_secondary">{{'Inactive users'| translate}}</h2>
    <p class="small mb-0">{{'Send invites to users who have not logged in' | translate}}</p>
</div>
<mat-dialog-content class="p-5 pt-0 m-0" data-simplebar>
    <div class="contant-sec pt-1 position-relative">
        <mat-checkbox class="custom_check fs12 fontMed letterspacingfull checkBg mb-3 ms-2"
            (change)="selectAllMember($event)"  [(ngModel)]="selectAllCheckbox">{{'Select All' | translate}}
        </mat-checkbox>

        <div class="mt-2" *ngFor="let member of MemberData">
            <div class="d-flex align-items-center ms-3">
                <mat-checkbox class="custom_check fs12 fontMed ms-2"
                    (change)="singleSelectMember($event,member)"  [(ngModel)]="member.checked"></mat-checkbox>
                <div
                    class="memberProfileImg boxShadow rounded-circle primaryBg d-flex align-items-center justify-content-center overflow-hidden wh41 flex-shrink-0">
                    <img class="img-fluid" src="assets/images/userdummywithbg.png" alt="">
                </div>
                <div class="ms-2">
                    <h6 *ngIf="!member?.first_name" class="small fontMed mb-0">{{member?.username}}</h6>
                    <h6 class="small fontMed mb-0">{{member?.first_name}} {{member?.last_name}}</h6>
                    <p class="small mb-0">{{member.account_email}}</p>
                </div>
            </div>
        </div>


    </div>
</mat-dialog-content>
<div class="px-5 pb-4">
    <button class="btn btn-primary rounded-pill commonBtn px-4" (click)="sendInvite()">{{'Send' | translate}} {{countMember}} {{'invites' | translate}}</button>
</div>