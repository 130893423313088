<section class="emailSignature h-100" data-simplebar>
    <!-- Middle header sart -->
    <!-- <div class="midheader d-none d-xl-block">

        <h6>Email Signature</h6>

    </div> -->
    <!-- Middle header end -->
    <div class="profileContainer d-flex mb-4 mt-5 pt-3">
        <div
            class="memberProfileImg boxShadow rounded-circle primaryBg d-flex align-items-center justify-content-center overflow-hidden ms-3 flex-shrink-0">
            <!-- <img *ngIf="userData?.photo" class="fit_img" [src]="profileImage"> -->
            <!-- <img *ngIf="!userData?.photo" class="img-fluid" src="assets/images/memberProfile_icon.svg" alt=""> -->
            <img  class="" [src]="showImage" [ngClass]="{'fit_img' : showImage != 'assets/images/memberProfile_icon.svg', 'img-fluid' : showImage == 'assets/images/memberProfile_icon.svg'}">

        </div>
        <div id="emailData" class="profileInfo ms-4">
            <h5 class="fs21 font-normal text_primary" [style.color]="color">{{userData?.first_name}} {{userData?.last_name }}</h5>
            <p class="small m-0">{{userData?.position}}</p>
            <p class="small m-0 lh-1">{{userData?.company}}</p>
            <p class="small text_gray m-0">{{userData?.address}}</p>
            <p class="small text_gray m-0">{{userData?.phone}}</p>
            <p  class="small text-decoration-underline text_primary cursor_pointer" [style.color]="color"><a href="{{digilink}}" target="_blank">{{digitext | translate}}</a></p>

        </div>
    </div>

    <hr style="color: #EBEBEB; opacity: 1;" />
    <!-- [cdkCopyToClipboard]="array" (click)="copySign()" -->
    <div class="signature_button mx-auto text-center mt-5">
        <button class="btn d-block w-100 whiteBg border rounded-pill py-0 fs12 fontMed px-3 h36"
            [cdkCopyToClipboard]="emailData" (click)="copySign()">{{'Copy Signature'| translate}}</button>
        <p class="fs11 text_gray mt-3">{{'Once copied, paste in your Gmail, Hotmail, or other emailOnce copied, paste in your Gmail, Hotmail, or other email client signature' | translate}}</p>
        <!-- [href]="getStringifiedAssets([demoAssets[0]], demoSubject)" -->
        <button
            class="btn d-block w-100 btn-primary commonBtn shadow-none rounded-pill py-0 fs12 fontMed px-3 h36 ms-1"><a
                [href]="getStringifiedAssets([demoAssets[0]], demoSubject)"
                style="color: #FFFFFF;text-decoration: none;">{{'Send to My Email'| translate}}</a></button>
        <p class="fs11 text_gray mt-3">{{'Once sent to your email you can open on a computer and copy and paste into your email client'| translate}}</p>

    </div>


</section>