import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  whatsappForm: FormGroup;
  highlyttoggle: boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  addLinkForm?: FormGroup;
  phoneUtil = PhoneNumberUtil.getInstance();
  isValid: boolean = false;
  formattedNumber:any;
  telInputOptions = {
    initialCountry: 'in',
    autoHideDialCode: false,
    nationalMode: false
  };
  countryCodeSelected:string = 'in';
  inputIsChanged: boolean = false;
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<WhatsappComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private message: MessagehandlingService, private api: ApiService) {
    this.currentRoute = window.location.pathname
    this.whatsappForm = this.fb.group({
      highlight: '',
      title: this.data.block.name,
      description: '',
      active: '',
      country_code: 'in',
      dial_code: '',
      content:'',
      type:'',
      slug:'whatsapp',

    })
  }

  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }


  onCountryChange(data: any) {
    debugger
    this.whatsappForm.patchValue({
      country_code: data.iso2,
      dial_code: data.dialCode
    });
    this.countryCodeSelected = data.iso2;
  this.formatPhoneNumber();
}



  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  removeCountryCode(content: string): string {
    return content.replace(/^\++\d+/, '');
  }

  submitForm() {
    console.log(this.isValid);
    
    if (!this.isValid) {
      this.message.errorMessage('Please fix the phone number before submitting.', 2000);
      return; // Prevent form submission
    }
    let a = this.whatsappForm.value.dial_code.slice(this.whatsappForm.value.country_code.length+1);
    let dailCode =  a.replace(/\s+/g, '');
    let countryCode = this.whatsappForm.value.country_code;

if (!countryCode.startsWith('+')) {
  countryCode = `+${countryCode}`;
}


    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      const hasEmptyFields = (
          !this.whatsappForm.value.title ||
           !this.whatsappForm.value.dial_code 
          // !dailCode
      );

      if (hasEmptyFields) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
      }
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      let formData = new FormData();
      formData.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
      formData.append('row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.whatsappForm.value.title)
      formData.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
      formData.append('dial_code', this.whatsappForm.value.dial_code)
      formData.append('content', this.removeCountryCode(this.whatsappForm.value.content))
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
          const hasEmptyFields = (
              !this.whatsappForm.value.title 
              // !this.whatsappForm.value.country_code ||
         ||     !this.whatsappForm.value.dial_code
          );

          if (hasEmptyFields) {
              this.message.errorMessage('Please fill in all required fields.', 2000);
              return;
          }
  
        let formData = new FormData
        formData.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
        formData.append('row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.whatsappForm.value.title)
        formData.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
        // formData.append('country_code', this.whatsappForm.value.country_code)
        formData.append('dial_code', this.whatsappForm.value.dial_code)
        formData.append('content', this.whatsappForm.value.content)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }


  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }

  get content() {
    return this.whatsappForm.get('content')!.value
  }

  previewLink() {
    let contentWithoutPlus = this.content.replace('+', '').replace(/\s+/g, '');
    window.open(this.selectedSocialInfo?.base_url + contentWithoutPlus);
  }
  

//   telInputObject(obj:any) {
//   setTimeout(() => {                           //<<<---using ()=> syntax
//     obj.setNumber('+'+this.whatsappForm.value.country_code+this.whatsappForm.value.dial_code)
//   }, 1000);
// }

telInputObject(obj: any) {
  obj.setCountry(this.whatsappForm.value.country_code);
    obj.setNumber('+' + this.whatsappForm.value.dial_code + this.whatsappForm.value.content);
  //this.formatPhoneNumber();
  

}


onInputChange(event: any) {
  const inputValue: string = event.target.value;
  this.whatsappForm.get('country_code')?.setValue(this.countryCodeSelected);
}

formatPhoneNumber() {
  if (this.whatsappForm.value.content && this.whatsappForm.value.country_code && typeof this.whatsappForm.value.content === 'string' && typeof this.whatsappForm.value.country_code === 'string' && this.whatsappForm.value.content?.length>5) {
      const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.whatsappForm.value.content, this.whatsappForm.value.country_code);
      this.isValid = this.phoneUtil.isValidNumber(parsedNumber);
      this.formattedNumber = this.phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
      this.whatsappForm.get('content')?.setValue(this.formattedNumber);
      } else {
        this.isValid = false;
      }
}





getCountryCode(dialCode: string) {
  try {
    const regionCode = this.phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode, 10));
    const countryCode = regionCode;
    return countryCode;
  } catch (error) {
    console.error('Error getting country code:', error);
    return 'IN';
  }
}

 
}
