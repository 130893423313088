<div class="dialog_head p-5 position-relative">
    <button class="btn p-1 border-0 modal-close d-flex align-items-center justify-content-center"
        (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    <div class="d-flex align-items-end justify-content-between flex-wrap">
        <div class="heading-sec">
            <h2 class="mb-2 pb-1 text_secondary fw-bold lh-1">{{'Add content'| translate}}</h2>
            <p class="text_secondary small mb-1">{{'Select from our wide variety of links and contact info below.'| translate}}</p>
        </div>
        <!-- header Search box -->
        <div class="position-relative searchbox me-3">
            <input type="text" class="form-control rounded-5 border-0 inputH42 bg_light" placeholder="{{'Search content'| translate}}…"
                autocomplete="off" [(ngModel)]="searchText">
            <span class="searchIcon position-absolute"><img src="assets/images/search_icon.svg" /></span>
        </div>
    </div>
</div>
<mat-dialog-content class="p-5 pt-0 m-0" data-simplebar>
    <div class="content_sec">
        <div *ngFor="let icon of iconData">
            <h4 class="text_secondary fs17 fontMed mb-4 lh-1 mt-4">{{icon.name}}</h4>
            <div class="row g-3">
                <div *ngFor="let img of icon.value | filter : searchText" class="col-md-6 col-lg-4">
                    <div class="content-item bg_light d-flex align-items-center">
                        <div class="icon-wrap me-3 flex-sherink-0 position-relative " > 
                            <img [src]="ImageBaseUrl + '/' + img.image" class="imgCls">
                            <p *ngIf="img.pro==1" class="pro-btn pro-btn-position">Pro</p>
                        </div> 
                        <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                            <p class="small fontMed text-black m-0">{{img.name}}</p>
                            <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                                (click)="addLink(img)">{{'Add'| translate}}</button>
                        </div>
                    </div>

                    <!-- <button (click)="">Dummy</button> -->
                    <!-- <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/facebook.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Facebook</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">LinkedIn</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/youtube.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Youtube</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/instagram1.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Instagram</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/tiktok.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">TikTok</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/pinterest.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Pinterest</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/snapchat.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Snapchat</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/twitter.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Twitter</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/behance.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Behance</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/devianART.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">DevianART</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/tumblr.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Tumblr</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/quora.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Quora</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/dribbble.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Dribbble</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/reddit.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Reddit</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/meetup.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Meetup</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/tinder.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Tinder</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/twitch.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Twitch</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/foursquare.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Foursquare</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/qzone.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Qzone</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/weibo.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Weibo</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/renren.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Renren</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div> -->
                </div>
                <!-- 
        <h4 class="text_secondary fs17 fontMed mb-4 lh-1">Quick Access buttons</h4>
        <div class="row g-3">
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/calendar.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Calendar</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/file.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">File</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/link.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Link</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/cv.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">CV</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-sherink-0">
                        <img src="assets/images/b-App.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">B-App</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>
        </div> -->
            </div>
        </div>
    </div>
</mat-dialog-content>