import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
// import { AddContentComponent } from '../add-content/add-content.component';
// import { EventsComponent } from '../events/events.component';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-events',
  templateUrl: './edit-events.component.html',
  styleUrls: ['./edit-events.component.scss']
})
export class EditEventsComponent {
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  highlyttoggle: boolean = false;
  EventForm: FormGroup
  profileData: any=[]
  selectedProfileData: any = [];
  selectedProfileCount: any = 0;
  selectAllCheckbox: boolean = false;
  profileImageUrl=environment.userImage
  searchText: any
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<EditEventsComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private formBuilder: FormBuilder, private api: ApiService, public message: MessagehandlingService) {
    this.EventForm = this.formBuilder.group({
      title: data.block.name,
      start_date: [''],
      end_date: [''],
      address_line1: [''],
      address_line2: [''],
      postcode: [''],
      city: [''],
      state: [''],
      country: [''],
      url: [''],
      description: [''],
      type: [''],
      id: [''],
      highlight: ['']
    })
  }

  ngOnInit(): void {
    this.highlyttoggle=this.data.block.highlight == '' || this.data.block.highlight == '0' ? false : true,
    this.getMemberData()
    this.getEventData()
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }



  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       //  
  //       this.profileData = response.items
  //     }
  //   })
  // }
  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element:any) => {
        if(element.member_status!=4){
        this.profileData.push(element)
        }
      });
    })
  }


  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    
    if (this.selectedProfileData != '') {
      let fd = new FormData
      let stateDate = moment(this.EventForm.value.start_date).format('YYYY-MM-DD hh:mm:ss');
      let endDate = moment(this.EventForm.value.end_date).format('YYYY-MM-DD hh:mm:ss');
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.EventForm.value.highlight == '' || this.EventForm.value.highlight == false ? '0' : '1')
      fd.append('slug', this.selectedSocialInfo?.slug)
      fd.append('start_date', stateDate)
      fd.append('end_date', endDate)
      fd.append('address_line1', this.EventForm.value.address_line1)
      fd.append('address_line2', this.EventForm.value.address_line2)
      fd.append('postcode', this.EventForm.value.postcode)
      fd.append('city', this.EventForm.value.city)
      fd.append('state', this.EventForm.value.state)
      fd.append('country', this.EventForm.value.country)
      fd.append('url', this.EventForm.value.url)
      fd.append('description', this.EventForm.value.highlight == true ? this.EventForm.value.description : '')
      fd.append('title', this.EventForm.value.title)
      fd.append('type', 'primary')
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()

      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;

      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)
        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }



  getEventData() {
    let fd = new FormData
    fd.append('id', this.data.block.id)
    this.api.getEvent(fd).subscribe((res: any) => {
      setTimeout(() => {
        this.EventForm.patchValue({
          title: res.event.title,
          start_date: new Date(res.event.start_date),
          end_date: new Date(res.event.end_date),
          address_line1: res.event.address_line1,
          address_line2: res.event.address_line2,
          postcode: res.event.postcode,
          city: res.event.city,
          state: res.event.state,
          country: res.event.country,
          url: res.event.url,
          description: res.event.description,
          highlight: res.event.highlight == '' || res.event.highlight == '0' ? false : true,
        })
      }, 1000);

    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {

    this.dialogRef.close();

  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })
  }



  submitForm() {
    if (this.EventForm.value.highlight && !this.EventForm.value.description) {
      this.message.errorMessage('Please provide a description.', 2000);
      return;
    }
    if (
      !this.EventForm.value.start_date ||
      !this.EventForm.value.end_date ||
      !this.EventForm.value.address_line1 ||
      !this.EventForm.value.postcode ||
      !this.EventForm.value.city ||
      !this.EventForm.value.state ||
      !this.EventForm.value.country ||
      !this.EventForm.value.title
    ) {
      this.message.errorMessage('Please fill in all required fields.', 2000);
      return;
    } else {
      let stateDate = moment(this.EventForm.value.start_date).format('YYYY-MM-DD hh:mm:ss');
      let endDate = moment(this.EventForm.value.end_date).format('YYYY-MM-DD hh:mm:ss');
      let formData = new FormData();
      formData.append('id', this.selectedSocialInfo.id)
      formData.append('highlight', this.EventForm.value.highlight == '' || this.EventForm.value.highlight == false ? '0' : '1')
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('start_date', stateDate)
      formData.append('end_date', endDate)
      formData.append('address_line1', this.EventForm.value.address_line1)
      formData.append('address_line2', this.EventForm.value.address_line2)
      formData.append('postcode', this.EventForm.value.postcode)
      formData.append('city', this.EventForm.value.city)
      formData.append('state', this.EventForm.value.state)
      formData.append('country', this.EventForm.value.country)
      formData.append('url', this.EventForm.value.url)
      formData.append('description', this.EventForm.value.highlight == true ? this.EventForm.value.description : '')
      formData.append('title', this.EventForm.value.title)
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
      formData.forEach((val, key) => {
      })
    }
  }

  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })

  }

}
