import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {
  hide: boolean = true;
  show: boolean = true;
  changePasswordForm: FormGroup;


  constructor(private app: AppComponent, private api: ApiService, private router: Router, public message: MessagehandlingService) {
    this.changePasswordForm = new FormGroup({
      password: new FormControl(''),
      confirmPassword: new FormControl('')

    })
  }

  hideShowPassword() {
    this.hide = !this.hide;
  }

  hideConfirmPassword() {
    this.show = !this.show;

  }

  submitData() {
    if (this.changePasswordForm.value.password != this.changePasswordForm.value.confirmPassword) {
      this.message.errorMessage('password and confirm password must be the same.', 1000)
    }
    else {
      let fd = new FormData
      fd.append('new_password', this.changePasswordForm.value.password)
      fd.append('confirm_password', this.changePasswordForm.value.confirmPassword)
      this.api.updatePassword(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        this.router.navigate(['/login'])

      })
    }
  }

}
