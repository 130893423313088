<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <button (click)="backToAddContent()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">{{'Back'| translate}}</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <!-- <h5 *ngIf="currentRoute=='/profile' ||currentRoute=='/profile/content' "
        class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add
        {{selectedSocialInfo?.name}} link to
        1 profiles</h5> -->

    <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">{{'Add'| translate}}
        {{selectedSocialInfo?.name}} {{'link to'| translate}} 
        {{selectedMemberCount}}<span *ngIf="selectedMemberCount== 1" >{{'profile'| translate}}</span><span *ngIf="selectedMemberCount> 1" >{{'profiles'| translate}}</span></h5>
    <!-- <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add Messages</h5> -->
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap"><img src="assets/images/messages_icon.svg"></div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">{{selectedSocialInfo?.name}}</p>
        </div>
        <div class="padding-content pt-0">
            <!-- form content -->
            <form [formGroup]="messageForm" class="">
                <div class="toggle-sec d-flex align-items-center justify-content-between pt-4 pb-3 mb-1">
                    <div class="customSlideToggle rightText">
                        <mat-slide-toggle class="fs11 fontMed" [checked]="highlyttoggle" formControlName="highlight"
                            (change)="highlightbtn($event)">
                            {{'Highlight'| translate}}</mat-slide-toggle>
                    </div>
                    <p class="text_primary text-decoration-underline fontMed fs11 mb-0" (click)="previewLink()">{{'Preview Link'| translate}}</p>
                </div>
                <div class="inputwrap position-relative mb-3">
                    <input class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed ps-5" type="text"  (keypress)="numberOnly($event)"
                    (countryChange)="onCountryChange($event)"  formControlName="content" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'in'}" placeholder="{{'Enter Number'| translate}}"/>

                </div>
                <p class="fs14 text_gray mb-4 pt-1 ps-2 pb-1"><span
                        class="text_primary">{{content}}</span></p>
                <div class="inputwrap position-relative mb-3">
                    <textarea class="form-control fs14 p-3 mb-3 inputBg rounded-3 border-0 text-black fontMed" rows="3"
                        formControlName='msg_body' placeholder="{{'Text here'| translate}}..."></textarea>
                </div>
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">{{'Title'| translate}}</label>
                    <input
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        formControlName='title'>
                </div>
                <div *ngIf="highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">{{'Description'| translate}}</label>
                    <textarea formControlName="description"
                        class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        id="description">Check out my trip to Greece's new post!</textarea>
                </div>
                <button (click)="submitForm()" class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none"
                    [ngClass]="{ 'disabledBtn':messageForm.invalid}">{{'Add link'| translate}}</button>
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <h2 class="fs17 fontMed mb-3 text-center">{{'URL of your Website'| translate}}</h2>
                <p class="fs13 mb-0 pb-1 text_secondary text-center">{{'Description'| translate}}...</p>
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto"
                    data-bs-dismiss="modal" style="color: #498BE5;">{{'Got it'| translate}}!</button>
            </div>
        </div>
    </div>
</div>