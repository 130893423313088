import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { MessagehandlingService } from '../message/messagehandling.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  baseurl = environment.baseurl;
  // baseImageUrl = 'https://staging.b-intouch.me/public/uploads/user_images/'
  baseSocialImageUrl = 'https://staging.b-intouch.me/public/uploads/service_icons_v2'
  baseSocialImageGreyUrl = 'https://staging.b-intouch.me/public/uploads/service_icons_v2/grey/'
  // viewProfileUrl = 'https://b-in-touch-staging.web.app/profile/'
   viewProfileUrl= 'https://frontend-stage.b-intouch.me/profile/'
  constructor(private http: HttpClient, public router: Router, private message: MessagehandlingService) { }

  login(body: any) {
    return this.http.post(this.baseUrl + `/authenticate_user`, body)
  }

  logOut(body: any) {
    return this.http.post(this.baseurl + `/sign_out`, body)
  }

  signup(body: any) {
    return this.http.post(this.baseUrl + '/register', body)
  }

  forgotpassword(body: any) {
    return this.http.post(this.baseUrl + '/forgot_password', body)
  }

  updatePassword(body: any) {
    return this.http.post(this.baseUrl + `/change_password`, body)
  }

  resetPwd(body: any) {
    return this.http.post(this.baseUrl + `/reset_password_authenticate `, body)

  }


  members(body: any) {
    return this.http.post(this.baseUrl + `/send_invitation_email`, body)
  }

  reArrangeMemberData(body:any){
    return this.http.post(this.baseUrl + `/update_team_order`, body)

  }

  assignMember(body: any) {
    return this.http.post(this.baseUrl + `/assign_member_email`, body)

  }


  about(body: any) {
    return this.http.post(this.baseUrl + `/edit_profile`, body)
  }

  aboutLandingApi(body: any) {
    return this.http.post(this.baseUrl + `/get_user`, body)
  }


  switchProfile(body: any) {
    return this.http.post(this.baseUrl + `/switch_profile`, body)
  }

  updateUserRole(body: any) {
    return this.http.post(this.baseUrl + `/update_user_role`, body)
  }

  userTeamList(body: any) {

    return this.http.post(this.baseUrl + `/get_user_teams`, body).pipe(map((res: any) => {
      if (res.http_status == 401) {
        this.message.errorMessage('Another user logged into account via same cred. Please login again', 5000);
        localStorage.removeItem('bteamsToken')
        sessionStorage.removeItem('usertoken')
        this.router.navigate(['login'])
      }
      else {
        return res
      }
    }))

  }

  appIcons(body: any) {
    return this.http.post(this.baseurl + `/get_app_icons`, body)
  }

  updateLink(body: any) {
    return this.http.post(this.baseurl + `/save_user_service`, body)
  }

  deleteLink(body: any) {
    return this.http.post(this.baseurl + `/delete_user_service`, body)
  }

  getActiveIcons(body: any) {
    return this.http.post(this.baseurl + `/get_user_active_services`, body)
  }

  saveUserContact(body: any) {
    return this.http.post(this.baseurl + `/save_user_contact`, body)
  }

  getActiveAppIcon(body: any) {
    return this.http.post(this.baseurl + `/get_active_app_icons`, body)   // for business template
  }

  saveLinksForMultipleProfile(body: any) {
    return this.http.post(this.baseUrl + `/save_users_service`, body)
  }


  reArrangeIcons(body: any) {
    return this.http.post(this.baseurl + `/update_services_order`, body)
  }


  getEditIcons(body: any) {
    return this.http.post(this.baseurl + `/get_service_icons`, body)
  }


  qrCodeGenerate(body: any) {
    return this.http.post(this.baseurl + `/generate_qrcode`, body)
  }

  duplicatMember(body: any) {
    return this.http.post(this.baseUrl + `/copy_account`, body)
  }

  lockMemberStatus(body: any) {
    return this.http.post(this.baseUrl + `/update_lock_status`, body)
  }


  getLeadData(body: any) {
    return this.http.post(this.baseUrl + `/get_lead_form`, body)
  }

  saveLeadForm(body: any) {
    return this.http.post(this.baseUrl + `/save_lead_form`, body)
  }

  reArrangeLeadsData(body:any){
    return this.http.post(this.baseUrl + `/update_lead_order`, body)

  }

  captureLeadForm(body: any) {
    return this.http.post(this.baseUrl + `/capture_lead`, body);
  }

  updateProfileTemplate(body: any) {
    return this.http.post(this.baseUrl + `/update_profile_theme`, body)
  }

  userServiceActivate(body: any) {
    return this.http.post(this.baseurl + `/user_service_activate`, body)

  }

  getProfileStyle(body: any) {
    return this.http.post(this.baseurl + `/get_profile_style`, body)
  }

  getServices(body: any) {
    return this.http.post(this.baseurl + `/get_services_by_username`, body)
  }

  instantMode(body: any) {
    return this.http.post(this.baseUrl + `/update_instant_mode`, body)
  }

  leadCptureMode(body: any) {
    return this.http.post(this.baseUrl + `/update_lead_capture_mode`, body)
  }

  emailIntro(body: any) {
    return this.http.post(this.baseUrl + `/update_email_intro`, body)
  }

  getSubTeams(body: any) {
    return this.http.post(this.baseUrl + `/get_sub_teams`, body)
  }

  saveSubTeam(body: any) {
    return this.http.post(this.baseUrl + `/save_sub_team`, body)
  }

  saveSubTeamUser(body: any) {
    return this.http.post(this.baseUrl + `/save_sub_team_user`, body)
  }

  deleteSubTeam(body: any) {
    return this.http.post(this.baseUrl + `/delete_sub_team`, body)
  }


  getSubTeamUser(body: any) {
    return this.http.post(this.baseUrl + `/get_sub_team_users`, body)
  }

  resendInvite(body: any) {
    return this.http.post(this.baseUrl + `/resend_invite_email`, body)
  }

  resetPassword(body: any) {
    return this.http.post(this.baseUrl + `/reset_password_email`, body)
  }

  getUserLeads(body: any) {
    return this.http.post(this.baseUrl + `/get_user_leads`, body)
  }

  updateUserLeads(body: any) {
    return this.http.post(this.baseurl + `/update_connect`, body)

  }

  deleteUserLead(body: any) {
    return this.http.post(this.baseurl + `/delete_capture_lead`, body)

  }

  uploadImage(body: any) {
    return this.http.post(this.baseUrl + `/set_profile_images`, body)

  }

  // switchUserProfile(body:any){
  //   return this.http.post(this.baseUrl + `/switch_profile`, body)
  // }

  profileList(body: any) {
    return this.http.post(this.baseurl + `/get_user_profiles`, body)
  }


  saveScheduleEmail(body: any) {
    return this.http.post(this.baseUrl + `/save_schedule_email`, body)

  }


  getUsersEmail(body: any) {
    return this.http.post(this.baseUrl + `/get_users_email`, body)

  }

  notification(body: any) {
    return this.http.post(this.baseUrl + `/save_push_notification`, body)

  }

  getDevicesList(body: any) {
    return this.http.post(this.baseUrl + `/get_user_devices`, body)

  }

  saveDevice(body: any) {
    return this.http.post(this.baseurl + `/save_user_nfctag`, body)

  }

  deactivateDevice(body: any) {
    return this.http.post(this.baseurl + `/remove_user_nfctag`, body)
  }


  saveEvent(body: any) {
    return this.http.post(this.baseurl + `/save_event`, body)
  }

  getEvent(body: any) {
    return this.http.post(this.baseurl + `/get_user_event_by_id`, body)

  }

  editEmail(body: any) {
    return this.http.post(this.baseurl + `/update_account`, body)

  }

  removeTeamMember(body: any) {
    return this.http.post(this.baseUrl + `/delete_team_member`, body)

  }

  inviteToApp(body: any) {
    return this.http.post(this.baseUrl + `/invite_to_mobile_app`, body)

  }

  getPaymentBilling(body: any) {
    return this.http.post(this.baseUrl + `/get_payment_billing`, body)
  }

  saveBillingInfo(body: any) {
    return this.http.post(this.baseUrl + `/save_billing_info`, body)

  }

  supportEmail(body: any) {
    return this.http.post(this.baseUrl + `/support_email`, body)
  }

  getMemberState(body: any) {
    return this.http.post(this.baseUrl + `/get_member_stat`, body)
  }

  getTeamSetting(body:any){
    return this.http.post(this.baseUrl + `/get_team_setting`,body)

  }
  saveTeamSetting(body: any) {
    return this.http.post(this.baseUrl + `/save_team_setting`, body)
  }

  createSubscription(body: any) {
    return this.http.post(this.baseUrl + `/create_subscription`, body)
  }

  updateSubscription(body: any) {
    return this.http.post(this.baseUrl + `/update_card`, body)
  }

  cancelSubscription(body: any) {
    return this.http.post(this.baseUrl + `/cancel_subscription`, body)
  }

  getTutorials(body: any) {
    return this.http.post(this.baseurl + `/get_tutorials`, body)
  }

  getInvoices(body: any) {
    return this.http.post(this.baseUrl + `/get_invoices`, body)

  }

  getinsight(body: any) {
    return this.http.post(this.baseUrl + `/get_insights`, body)
  }

  leadGenearted(body: any) {
    return this.http.post(this.baseUrl + `/lead_generated`, body)
  }

  topLead(body: any) {
    return this.http.post(this.baseUrl + `/top_viewed_profile`, body)
  }

  top_tapped(body: any) {
    return this.http.post(this.baseUrl + `/top_tapped_link`, body)
  }

  most_active(body: any) {
    return this.http.post(this.baseUrl + `/most_active_devices`, body)
  }

  contact_downloads(body: any) {
    return this.http.post(this.baseUrl + `/contact_downloads`, body)

  }

  export_views_and_taps(body:any){
    return this.http.post(this.baseUrl + `/export_views_and_taps`, body)

  }
  view_instant_percent(body: any) {
    return this.http.post(this.baseUrl + `/view_instant_percent`, body)
  }

  claim_your_device(body: any) {
    return this.http.post(this.baseUrl + `/claim_your_device`, body)
  }


  getServiceDetail(data: any) {
    return this.http.post(this.baseurl + '/get_user_service_by_id/', data);
  }








  // CRM Integration API

  getCRMAuthentication() {
    return this.http.post(this.baseUrl + `/get_JWT_token`, '')
  }

  postCrmUpdateConnect(body: any) {
    return this.http.post(this.baseurl + `/update_connect`, body)
  }

  saveIntegrationSetting(body: any) {
    return this.http.post(this.baseUrl + `/save_integration_settings`, body)
  }

  getIntegrationSetting(body: any) {
    return this.http.post(this.baseUrl + `/get_integration_settings`, body)
  }

  deleteMember(body:any){
    return this.http.post(this.baseUrl+`/delete_account`,body)
  }


}
