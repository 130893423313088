
<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <button (click)="backToAddContent()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">{{'Back'| translate}}</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <h5 class="mb-1 mt-3 text-center text_secondary fw-bold lh-1">{{selectedSocialInfo.name}} {{'link'| translate}}</h5>
    <button class="btn deleteBtn border-0" (click)="deleteIcon(selectedSocialInfo.id,selectedSocialInfo.slug)"><img
        src="assets/images/deleteicn.svg" alt=""></button>
    <!-- <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add B-App Link</h5> -->
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap"><img src="assets/images/b-app_icon.png"></div>
            <!-- <p class="fs11 text_gray mt-2 mb-0 lh-1">B-App</p> -->
            <p class="fs11 text_gray mt-2 mb-0 lh-1">{{selectedSocialInfo.title}}</p>

        </div> 
        <p class="m-0 fs12 fontMed text_secondary mt-2 py-3 text-center">{{'This link will appear on your profile as a button'| translate}}</p>
        <div class="padding-content pt-0">
            <!-- form content -->
            <form class="">
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">{{'File'| translate}}</label>
                    <div class="position-relative">
                        <p class="text_primary fontMed fs11 mb-0 me-4 cursor_pointer" (click)="fileType()">{{'Choose from list'| translate}}...</p>
                        <span> <img  *ngIf="selectedSocialInfo?.tips!=''|| selectedSocialInfo?.tips!=null" class="infoicon cursor_pointer" src="assets/images/questionicn.svg" data-bs-toggle="modal"
                            data-bs-target="#userbameInfo"></span>
                    </div>
                </div>
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">{{'Title'| translate}}</label>
                    <input
                        class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none"
                        value="File">
                </div>

                <div *ngIf="highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">{{'Description'| translate}}</label>
                    <textarea class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        id="description">Check out my trip to Greece’s new post!</textarea>
                </div>

                <button class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none disabledBtn">{{'Update link'| translate}}</button>
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <h2 class="fs17 fontMed mb-3 text-center">{{'URL of your Website'| translate}}</h2>
                <p class="fs13 mb-0 pb-1 text_secondary text-center">{{selectedSocialInfo?.tips}}</p>
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto"
                    data-bs-dismiss="modal" style="color: #498BE5;">{{'Got it'| translate}}!</button>
            </div>
        </div>
    </div>
</div>