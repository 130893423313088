<div class="invite_wrap text-center w-100 m-auto">
    <div class="logo_img">
        <img class="" src="../../../assets/images/logo_login.png" alt="Logo"/>
    </div>
    <div class="invite_body">
        <span class=""><img class="mb-3" src="../../../assets/images/invite-checked.svg" alt="icon"/></span>
        <h2 class="mb-4">B-Team Invite Accepted</h2>
        <button class="btn btn-primary btn_xl rounded-pill commonBtn px-5 buttonConvert mb-4" [routerLink]="['/login']">Login Now</button>
        <p>For any question, please email us at:<span class="text_primary"> official@bTeam.com</span></p>
    </div>

</div>