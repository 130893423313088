import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { ChoosefiletypeComponent } from '../uploadfile/choosefiletype/choosefiletype.component';

@Component({
  selector: 'app-bapp',
  templateUrl: './bapp.component.html',
  styleUrls: ['./bapp.component.scss']
})
export class BappComponent implements OnInit {
  highlyttoggle: boolean = false;
  currentRoute: any = '';
  selectedSocialInfo: any = {};
  selectedMemberCount: number = 0;
  imageBaseUrl: any;
  addBappForm: FormGroup;
  profileIconArrayLength: any
  userInfo: any = {}
  userData: any
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<BappComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService, private message: MessagehandlingService) {
    
    this.currentRoute = window.location.pathname
    this.addBappForm = this.fb.group({
      highlight: '',
      content: '',
      title: this.data.block.name,
      description: '',
     
    })
  }
  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  fileType(): void {
    this.dialogRef.close();
    this.dialog.open(ChoosefiletypeComponent, {
      panelClass: ['cmn_modal', 'modal_sm'],
      width: '390px',
    });
  }

  submitForm() {
    if (window.location.pathname === '/profile' ||window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
        !this.addBappForm.get('title')?.value ||
        !this.addBappForm.get('content')?.value
      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('highlight', this.addBappForm.value.highlight == '' || this.addBappForm.value.highlight == false ? '0' : '1')
      formData.append('content', this.addBappForm.value.content)
      formData.append(' row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.addBappForm.value.title)
      formData.append('description', this.addBappForm.value.highlight == true ? this.addBappForm.value.description : '')
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
          !this.addBappForm.get('title')?.value ||
          !this.addBappForm.get('content')?.value
        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.addBappForm.value.highlight == '' || this.addBappForm.value.highlight == false ? '0' : '1')
        formData.append('content', this.addBappForm.value.content)
        formData.append(' row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.addBappForm.value.title)
        formData.append('description', this.addBappForm.value.highlight == true ? this.addBappForm.value.description : '')
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });

  }

  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })

  }

  get content() {
    return this.addBappForm.get('content')!.value
  }

  previewLink() {
    // window.open(this.selectedSocialInfo?.base_url + this.content)
  }



}
