import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  phoneForm: FormGroup;
  highlyttoggle: boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  dailCode: any;
  phoneUtil = PhoneNumberUtil.getInstance();
  isValid: boolean = false;
  formattedNumber:any;
  telInputOptions = {
    initialCountry: 'in',
    autoHideDialCode: false,
    nationalMode: false
  };
  countryCodeSelected:string = 'in';
  inputIsChanged: boolean = false;
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<PhoneComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private message: MessagehandlingService, private api: ApiService) {
    this.currentRoute = window.location.pathname
    this.phoneForm = this.fb.group({
      highlight: '',
      title: this.data.block.name,
      description: '',
      active: '',
      country_code: 'in',
      dial_code: '91',
      type: '',
      slug: '',
      content: ''
    })
  }
  
  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }


 
  onCountryChange(data: any) {
    this.phoneForm.patchValue({
      country_code: data.iso2,
      dial_code: data.dialCode
    });
    this.countryCodeSelected = data.iso2;
  this.formatPhoneNumber();
}

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  removeCountryCode(content: string): string {
    return content.replace(/^\++\d+/, '');
  }

  
telInputObject(obj: any) {
  obj.setCountry(this.phoneForm.value.country_code);
    obj.setNumber('+' + this.phoneForm.value.dial_code + this.phoneForm.value.content);
  //this.formatPhoneNumber();
  

}


onInputChange(event: any) {
  const inputValue: string = event.target.value;
  this.phoneForm.get('country_code')?.setValue(this.countryCodeSelected);
}

formatPhoneNumber() {
  if (this.phoneForm.value.content && this.phoneForm.value.country_code && typeof this.phoneForm.value.content === 'string' && typeof this.phoneForm.value.country_code === 'string' && this.phoneForm.value.content?.length>5) {
      const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.phoneForm.value.content, this.phoneForm.value.country_code);
      this.isValid = this.phoneUtil.isValidNumber(parsedNumber);
      this.formattedNumber = this.phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
      this.phoneForm.get('content')?.setValue(this.formattedNumber);
      } else {
        // this.message.errorMessage('Please enter correct phone number',1000)
        console.error('Invalid input types');
        this.isValid = false;
      }
}



getCountryCode(dialCode: string) {
  try {
    const regionCode = this.phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode, 10));
    const countryCode = regionCode;
    return countryCode;
  } catch (error) {
    console.error('Error getting country code:', error);
    return 'IN';
  }
}



  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }
  submitForm() {
    console.log(this.phoneForm.value.content);
    
    if (this.phoneForm.value.highlight && !this.phoneForm.value.description) {
      this.message.errorMessage('Please provide a description.', 2000);
      return;
    }
    let abc = this.phoneForm.value.content.split(this.phoneForm.value.dial_code);
    this.dailCode = abc[1]
    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
         !this.phoneForm.get('content')?.value ||
        !this.phoneForm.get('title')?.value 
        // !this.phoneForm.get('country_code')?.value ||
        // !this.phoneForm.get('dial_code')?.value
      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('highlight', this.phoneForm.value.highlight == '' || this.phoneForm.value.highlight == false ? '0' : '1')
      formData.append('row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.phoneForm.value.title)
      formData.append('description', this.phoneForm.value.highlight == true ? this.phoneForm.value.description : '')
      formData.append('country_code', this.phoneForm.value.country_code.toUpperCase())
      formData.append('dial_code', '+' + this.phoneForm.value.dial_code)
      formData.append('content', this.phoneForm.value.content)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)

    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
           !this.phoneForm.get('content')?.value ||
          !this.phoneForm.get('title')?.value 
          // !this.phoneForm.get('country_code')?.value ||
          // !this.phoneForm.get('dial_code')?.value
        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.phoneForm.value.highlight == '' || this.phoneForm.value.highlight == false ? '0' : '1')
        formData.append('row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.phoneForm.value.title)
        formData.append('description', this.phoneForm.value.highlight == true ? this.phoneForm.value.description : '')
        formData.append('country_code', this.phoneForm.value.country_code.toUpperCase())
        formData.append('dial_code', '+' + this.phoneForm.value.dial_code)
        formData.append('content', this.phoneForm.value.content)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }


  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }



  get content() {
    //   return '+'+this.phoneForm.get('country_code')!.value+''+this.phoneForm.get('dial_code')!.value

    return '+' + this.phoneForm.value.dial_code + this.phoneForm.get('content')!.value
  }

  previewLink() {
    let contentWithoutPlus = this.content.replace('+', '').replace(/\s+/g, '');
    window.open(this.selectedSocialInfo?.base_url + contentWithoutPlus);
  }

  //   telInputObject(obj:any) {
  //   setTimeout(() => {                          
  //     obj.setNumber('+'+this.phoneForm.value.country_code+this.phoneForm.value.dial_code)
  //   }, 1000);
  // }
}

