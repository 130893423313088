<div class="dialog_head p-5 pb-4 position-relative">
    <button class="btn p-1 border-0 modal-close d-flex align-items-center justify-content-center"
        (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    <div class="d-flex align-items-end justify-content-between flex-wrap">
        <div class="heading-sec">
            <h2 class="mb-2 h5 pb-1 text_secondary fw-bold lh-1">{{'B-in-Touch Teams Referral'| translate}}</h2>
            <p class="text_secondary small mb-3">{{'If the company you refer starts their free trial, you receive a free month!' | translate}}</p>
        </div>

    </div>
</div>
<mat-dialog-content class="p-5 pb-4 pt-0 m-0" data-simplebar>
    <div class="content_sec">
        <form class="" [formGroup]="emailForm">
            <div class="mb-4">
                <label class="fs16 fontMed mab-2 w-100">{{'Enter emails to send referral to, separated by commas' | translate}}</label>
                <mat-form-field class="w-100 customHeight100 h46" appearance="fill" formControlName="sendEmail">
                    <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let fruit of fruits" (removed)="remove(fruit)">
                            {{fruit}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <input class="h40 my-0" placeholder="{{'Enter emails to recieve invite, separated by commas' | translate}}"
                            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                </mat-form-field>
            </div>

            <div class="mb-3 pb-1">
                <label class="fs16 fontMed mab-2 w-100">{{'Message'| translate}}</label>
                <textarea class="form-control rounded-3 bodyLightColor border-0 p-3 fs12" rows="7"
                    placeholder="Message…" formControlName="message"></textarea>
            </div>
            <button
                class="btn btn-primary commonBtn shadow-none rounded-pill py-0 fs12 fontMed px-5 h36 ms-auto d-block" (click)="sendemail()">{{'Send'| translate}}</button>
        </form>
    </div>
</mat-dialog-content>